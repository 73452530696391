export const CREDITCARD = "creditcard";
export const DIRECTDEBIT = "directdebit";
export const CHINAPAYMENTS = "chinapayments";
export const ALIPAY = "alipay";
export const WECHATPAY = "wechatpay";
export const UNIONPAY = "unionpay";
export const LINK_ACCESS_TOKEN = "LINK_ACCESS_TOKEN";
export const SECURE_TOKEN = "SECURE_TOKEN";
export const HPF_INIT_TOKEN = "HPF_INIT_TOKEN";
export const ALPHA_SUCCESS_CODE = "1000";
export const HTTP_STATUS_OK = "200";
export const PAYMENT_METHODS = [{
    "payment_method": CREDITCARD
}, {
    "payment_method": DIRECTDEBIT
}];
export const PM_STATIC_DATA = {
    [CREDITCARD]: {
        title: "Credit Card",
        logo: 'assets/payment-methods/creditcard.png',
        frameStyleClass: 'pm-credit-card',
        disabled: false

    },
    [DIRECTDEBIT]:{
        title: "Direct Debit",
        logo: 'assets/payment-methods/directdebit.png',
        frameStyleClass: 'pm-direct-debit',
        disabled: false
    },
    [ALIPAY]:{
        title: "Alipay",
        logo: 'assets/payment-methods/alipay.png',
        secondaryLogo: 'assets/payment-methods/alipay-logo.svg',
        frameStyleClass: 'pm-alipay',
        disabled: false
    },
    [WECHATPAY]:{
        title: "WeChatPay",
        logo: 'assets/payment-methods/wechatpay.png',
        secondaryLogo: 'assets/payment-methods/wechat.svg',
        frameStyleClass: 'pm-wechatpay',
        disabled: false
    },
    [UNIONPAY]:{
        title: "Union Pay",
        logo: 'assets/payment-methods/unionpay.png',
        frameStyleClass: 'pm-union-pay',
        disabled: false
    }
}
export const PAYMENT_REQUEST_SUCCESS = "initPaymentSuccess";
export const PAYMENT_REQUEST_FAILED = "initPaymentFailed";
export const NONCE_REQUEST_FAILED = "nonceGenerationFailed";
export const HPF_INIT_TOKEN_REQUEST_SUCCESS = "initTokenAcquired";
export const HPF_INIT_TOKEN_REQUEST_FAILED = "initTokenFailed";

export const DEFAULT_POLLING_INTERVAL = 10000;