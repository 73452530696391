import {useEffect, useRef, useState} from "react";
import _ from "lodash";

import {ALIPAY, CREDITCARD, DEFAULT_POLLING_INTERVAL, DIRECTDEBIT, UNIONPAY, WECHATPAY} from '../utils/constants';
import HPFForm from './HPFForm';
import DirectDebitForm from "./DirectDebitForm";
import WeChatPayQR from "./WeChatPayQR";
import PaymentIFrame from "./PaymentIFrame";
import {fetchPaymentStatus} from "../utils/RequestHandlers";

function PaymentForm(props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const returnUrl = params.get("return_url");
    const timerIdRef = useRef(null);
    const {
        paymentInfo,
        paymentMethod,
        onCalculatedProp,
        onCardNumberReceivedC,
        processedFeeFetch,
        paymentQRCode,
        paymentWXCodeURL,
        paymentFrameUrl,
        paymentTransactionId,
        onPollingError,
        requestStatus,
        setRequestStatus
    } = props;
    const [isPollingEnabled, setIsPollingEnabled] = useState(getPollingEnabledStatus(paymentMethod.payment_method));

    useEffect(() => {
        const pollingCallback = async () => {
            try {
                const response = await fetchPaymentStatus(paymentInfo.access_token, paymentTransactionId);
                const paymentStatus = response.paymentTransaction.payment_status;
                setRequestStatusOnPaymentStatus(paymentStatus, paymentMethod.payment_method)
                if (requestStatus === "success") {
                    setIsPollingEnabled(false);
                } else if (requestStatus === "failed") {
                    setIsPollingEnabled(false);
                    onPollingError("Payment failed. Please try again.");
                }
            } catch (e) {
                setIsPollingEnabled(false);
                onPollingError("Payment failed. Please try again.");
                console.log('Polling failed. Stopped polling.');
                console.error(e);
            }
        };

        const startPolling = () => {
            timerIdRef.current = setInterval(pollingCallback, DEFAULT_POLLING_INTERVAL);
        };

        const stopPolling = () => {
            clearInterval(timerIdRef.current);
        };

        if (isPollingEnabled && (requestStatus == null || requestStatus === "")) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [isPollingEnabled, requestStatus]);

    function setRequestStatusOnPaymentStatus(paymentStatus, paymentMethod) {
        if (paymentStatus === "PAID" ||
            paymentStatus === "RECONCILED" ||
            paymentStatus === "SETTLED" ||
            (paymentMethod === UNIONPAY && paymentStatus === "PENDING")
        ) {
            setRequestStatus("success");
        } else if (paymentStatus === "FAILED" || paymentStatus === "ERROR" ||
            paymentStatus === "CANCELLED") {
            setRequestStatus("failed");
        } else {
            setRequestStatus(null);
        }

    }

    switch (paymentMethod.payment_method) {
        case CREDITCARD:
            return <HPFForm
                paymentInfo={paymentInfo}
                paymentLinkToken={paymentInfo.access_token}
                merchantId={paymentInfo.merchant.merchant_id}
                type={CREDITCARD}
                onCalculated={onCalculatedProp.bind(this)}
                onCardNumberReceivedCallCall={onCardNumberReceivedC.bind(this)}
            />
        case DIRECTDEBIT:
            return <DirectDebitForm
                paymentInfo={paymentInfo}
                isDDCountryNz={paymentInfo.dd_country === "NZ"}
                processedFeeFetch={processedFeeFetch}
            />
        case WECHATPAY:
        case ALIPAY:
            return <div></div>
        case UNIONPAY:
            return <PaymentIFrame
                webPageUrl={paymentFrameUrl}
            />
        default:
            return <div>Unknown payment method</div>
    }
}

function getPollingEnabledStatus(paymentMethod) {
    return  (
        ALIPAY === paymentMethod || WECHATPAY === paymentMethod || UNIONPAY === paymentMethod
    );
}

export default PaymentForm;